import React, { useEffect } from "react";

function Landing() {
  // Light blue to light gray - calm and professional
  // top #c7ecee - bottom #f5f6fa

  // Light green to light gray - fresh and modern
  // top #badc58 - bottom #f5f6fa

  // Light purple to light gray - sophisticated and elegant
  // top #a29bfe - bottom #f5f6fa
  const gradientStartEnd = "#000";
  const gradientMiddle = "#450077";
  const gradientMiddleHalfway = "#1f003b";
  // Data
  const projects = [
    {
      title: "Gestiona reserves i serveis",
      subtitle: "CRM per a hostaleria",
      description: "Ideal quan les eines genèriques no cobreixen les necessitats del teu negoci.",
      url: "https://samuraiflow.elbuensamurai.com",
      bg_img: "playground/samuraiflow.png",
    },
    {
      title: "Formularis avançats per a WordPress i Webs",
      subtitle: "Plugin WordPress + API REST",
      description: "Recopila dades, coneix els teus clients i optimitza basant-te en resultats!",
      url: "https://wordpress.elbuensamurai.com",
      bg_img: "playground/wordpress.png",
    },
    {
      title: "Prefereixes una aplicació?",
      subtitle: "APP Turisme + GPS tracking",
      description: "Descobreix l'aplicació de turisme amb mapes de calor que vam desenvolupar per a l'Ajuntament de Gandesa!",
      url: "https://appturisme.elbuensamurai.com",
      bg_img: "playground/appturisme.png",
    },
    {
      title: "Aplicacions web fetes a mida",
      subtitle: "Plataforma Web de tracking de tickets",
      description: "Investiga l'eina que ens va demanar un equip per a gestionar les despeses internes mensuals.",
      url: "https://tickets.elbuensamurai.com",
      bg_img: "playground/tickets.png",
    },
  ];

  // Loading screen
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("loading-screen").style.display = "none";
    }, 300);
  });

  return (
    <>
      {/* Loading screen */}
      <div
        id="loading-screen"
        className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-black opacity-10"
      >
        <div className="w-16 h-16 border-t-4 border-b-4 border-white rounded-full animate-spin"></div>
      </div>

      {/* Navbar */}
      <header className="">
        <nav className="border-gray-200 px-4 lg:px-6 py-2.5 fixed w-full z-10 bg-clip-padding backdrop-filter bg-opacity-80">
          <div className="flex flex-row items-center justify-between max-w-screen-xl mx-auto">
            {/* Logo */}
            <a
              className="flex flex-row px-2 bg-white bg-opacity-50 rounded-full place-content-center place-items-center"
              href="."
            >
              <img
                className="w-8 h-8"
                src="logo.png"
                alt=""
              />
              <span
                className="pt-1 ml-2 text-lg"
                style={{ fontFamily: "domoaregatonormal" }}
              >
                El Buen Samurai
              </span>
            </a>

            {/* Navbar links */}
            <div
              className="items-center justify-between hidden w-full text-white lg:flex lg:w-auto"
              id="mobile-menu-2"
            >
              <ul className="flex flex-col mt-4 font-medium text-white lg:flex-row lg:space-x-8 lg:mt-0">
                {["services", "products", "about", "contact"].forEach((element) => {
                  <li>
                    <a
                      href={"#" + element}
                      className="block py-2 pl-3 pr-4 rounded hover:text-blue-300 lg:bg-transparent lg:p-0"
                      aria-current="page"
                    >
                      {element.toUpperCase()}
                    </a>
                  </li>;
                })}
              </ul>
            </div>

            {/* Hamburguer icon */}
            <div className="flex items-center md:hidden">
              <button
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="mobile-menu-2"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </nav>
      </header>

      {/* Hero */}
      <div
        id="hero"
        className="block h-screen bg-opacity-50 bg-center bg-cover"
        style={{ backgroundImage: "url('bg.jpg')" }}
      >
        <div className="w-full h-full bg-black bg-opacity-50 backdrop-blur">
          <div className="flex flex-col justify-center h-full max-w-2xl px-6 py-10 mx-auto text-center pt-14 lg:px-8 sm:py-48 lg:py-10">
            <h1 className="text-4xl font-bold tracking-tight text-gray-100 sm:text-6xl">Eines personalitzades, Màrqueting i Dades</h1>
            <p className="hidden mt-6 text-lg leading-8 text-gray-400 md:block">Oferim serveis integrals de desenvolupament de software, màrqueting i consultoria per a microempreses i particulars. El nostre equip d'experts està dedicat a ajudar les empreses a tenir èxit en el món digital.</p>
            <div className="flex items-center justify-center mt-10 gap-x-6">
              <a
                href="#solutions"
                className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Comença Ara
              </a>
              {/* <a
                href="/playground"
                className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Ensenya'm els projectes
              </a> */}
            </div>
          </div>
        </div>
      </div>

      {/* Project cards */}
      <div
        className="flex flex-col w-full py-10"
        id="solutions"
        style={{ background: "linear-gradient(to bottom, " + gradientStartEnd + ", " + gradientMiddle + ", " + gradientStartEnd + ")", backdropFilter: "blur(10px)" }}
      >
        {/* Title */}
        <div className="max-w-2xl pt-10 mx-auto lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-200">Inspira't</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Els nostres projectes</p>
        </div>

        {/* Carousel holder */}
        <div className="flex flex-row justify-center">
          {/* <div className="space-x-5 carousel carousel-center"> */}
          <div className="carousel carousel-start md:carousel-center w-[20rem] md:w-full md:max-w-5xl space-x-4 md:space-x-20 rounded-box">
            {projects.map((project, index) => (
              <>
                <a
                  key={index}
                  id={"project" + (index + 1)}
                  href={project.url}
                  rel="noreferrer noopener"
                  target="_blank"
                  className="pt-24 outline-none focus:outline-none carousel-item"
                >
                  {/* This helps with the vertical centering on link click */}
                  <div className="flex flex-col p-3 bg-[#4b0082] shadow-md rounded-xl max-w-xs w-[18rem] md:w-full">
                    <img
                      className="object-cover w-full h-48 rounded-lg"
                      src={project.bg_img}
                      alt="Màrqueting i xarxes socials"
                    />
                    <dt className="flex flex-col mt-4 text-white gap-y-1">
                      {/* Title */}
                      <span className="text-lg font-semibold">{project.title}</span>
                      {/* Subtitle */}
                      <span className="text-sm">{project.subtitle}</span>
                    </dt>

                    <dd className="flex flex-col flex-auto mt-2 text-base leading-7 text-indigo-200">
                      <p className="whitespace-pre-wrap ">{project.description}</p>
                      <div className="flex-grow"></div>
                      <p className="mt-6">
                        <button className="text-sm font-semibold leading-6 text-white">
                          Veure més&nbsp;
                          <span aria-hidden="true">→</span>
                        </button>
                      </p>
                    </dd>
                  </div>
                </a>
              </>
            ))}
          </div>
          {/* Carousal navigation buttons */}
          <div class="hidden absolute md:flex justify-between transform -translate-y-1/2 left-10 right-10 top-1/2">
            <a
              href="#project1"
              class="btn btn-circle"
            >
              ❮
            </a>
            <a
              href="#project4"
              class="btn btn-circle"
            >
              ❯
            </a>
          </div>
        </div>
        {/* Carousal navigation buttons */}
        <div className="flex justify-center w-full gap-2 py-2 bg-transparent md:hidden">
          {projects.map((project, index) => (
            <a
              key={index}
              href={"#project" + (index + 1)}
              className="btn btn-xs btn-circle"
            >
              {index + 1}
            </a>
          ))}
        </div>
      </div>

      {/* Spacer */}
      <div className="py-10 bg-black">&nbsp;</div>

      {/* Contact */}
      <div
        className="flex flex-col w-full"
        id="contact"
      >
        <div
          className="py-32"
          style={{ background: "linear-gradient(to bottom, " + gradientStartEnd + ", " + gradientMiddleHalfway + ", " + gradientMiddle + ", " + gradientStartEnd + ")", backdropFilter: "blur(10px)" }}
        >
          {/* Contact methods */}
          <div className="flex flex-wrap items-center w-full mt-10 justify-evenly">
            {/* #fef4f5 => #7f3e50 */}
            <a
              href="mailto:albert@elbuensamurai.com"
              target="_blank"
              rel="noreferrer"
              className="max-w-[500px] items-center bg-[#7f3e50] text-white rounded-md cursor-pointer flex flex-row justify-start mt-[1rem] min-w-[290px] p-[1rem] transition-all"
            >
              <img
                src="email.png"
                alt="email"
                className="h-[40px] w-[40px] mx-[0.7rem]"
              />
              <p className="text-sm text-left">albert@elbuensamurai.com</p>
            </a>
            {/* #f2f7fb => #3c4f65 */}
            <a
              href="tel:+34663746221"
              target="_blank"
              rel="noreferrer"
              className="max-w-[500px] items-center bg-[#3c4f65] text-white rounded-md cursor-pointer flex flex-row justify-start mt-[1rem] min-w-[290px] p-[1rem] transition-all"
            >
              <img
                src="mobile.png"
                alt="mobile"
                className="h-[40px] w-[40px] mx-[0.7rem]"
              />
              <p className="text-sm text-left">+34 663746221</p>
            </a>
            {/* #f0fff4 => #2e3d32 */}
            <a
              href="https://wa.me/34663746221"
              target="_blank"
              rel="noreferrer"
              className="max-w-[500px] items-center bg-[#2e3d32] text-white rounded-md cursor-pointer flex flex-row justify-start mt-[1rem] min-w-[290px] p-[1rem] transition-all"
            >
              <img
                src="whatsapp.png"
                alt="whatsapp"
                className="h-[40px] w-[40px] mx-[0.7rem]"
              />
              <p className="text-sm text-left">+34 663746221</p>
            </a>
          </div>

          {/* TODO: Form */}
          {false && (
            <form className="px-10 mx-auto max-w-7xl">
              {/* Header */}
              <div className="max-w-2xl mx-auto lg:text-center">
                <h2 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Com podem ajudar-te?</h2>
              </div>

              {/* Content */}
              <div className="max-w-2xl mx-auto mt-16 sm:mt-20 lg:mt-24">
                {/* Radio buttons */}
                <div className="grid justify-center grid-cols-3 gap-x-8 gap-y-16">
                  <div className="flex flex-row justify-center p-3 bg-[#4b0082] shadow-md rounded-xl items-center text-base font-semibold leading-7 text-white gap-x-3">
                    <input
                      type="radio"
                      name="tipus_ajuda"
                      id="Desenvolupament de Software"
                    />
                    <label htmlFor="Desenvolupament de Software">Desenvolupament de Software</label>
                  </div>
                  <div className="flex flex-row justify-center p-3 bg-[#4b0082] shadow-md rounded-xl items-center text-base font-semibold leading-7 text-white gap-x-3">
                    <input
                      type="radio"
                      name="tipus_ajuda"
                      id="Màrqueting"
                    />
                    <label htmlFor="Màrqueting">Màrqueting</label>
                  </div>
                  <div className="flex flex-row justify-center p-3 bg-[#4b0082] shadow-md rounded-xl items-center text-base font-semibold leading-7 text-white gap-x-3">
                    <input
                      type="radio"
                      name="tipus_ajuda"
                      id="Consultoria"
                    />
                    <label htmlFor="Consultoria">Consultoria</label>
                  </div>
                </div>

                {/* Name input */}
                <div className="mt-10">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-5 text-gray-400"
                  >
                    Nom
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      id="name"
                      className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                      placeholder="Nom"
                    />
                  </div>
                </div>

                {/* Email input */}
                <div className="mt-10">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-5 text-gray-400"
                  >
                    Correu electrònic
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      id="email"
                      type="email"
                      className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                      placeholder="Correu electrònic"
                    />
                  </div>
                </div>

                {/* Message input */}
                <div className="mt-10">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium leading-5 text-gray-400"
                  >
                    Missatge
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <textarea
                      id="message"
                      rows="4"
                      className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none resize-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                      placeholder="Missatge"
                    ></textarea>
                  </div>
                </div>

                {/* Submit button */}
                <div className="mt-10">
                  <span className="block w-full rounded-md shadow-sm">
                    <button
                      type="button"
                      className="flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-gray-600 border border-transparent rounded-md hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700"
                    >
                      Enviar
                    </button>
                  </span>
                </div>
              </div>
              {/* Content end */}
            </form>
          )}
        </div>
      </div>

      {/* Footer and attributions */}
      <div className="text-xs text-gray-900 bg-black">
        <div>Imatges de projectes amb llicència i permís,</div>
        <div>
          també de&nbsp;
          <a
            className="underline"
            href="https://www.freepik.com/free-photo/overhead-view-grey-craftpapers-blue-background_2857131.htm#query=portfolio%20background&position=35&from_view=keyword&track=ais"
          >
            Freepik
          </a>
        </div>
        <div>
          i de Mikael Blomkvist a&nbsp;
          <a
            className="underline"
            href="https://www.pexels.com/photo/person-holding-white-ipad-on-brown-wooden-table-6476589/"
          >
            Pexels
          </a>
        </div>
      </div>
    </>
  );
}

export default Landing;
